import React, { useEffect, Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Dashboard from './dashboard';
import withAdminLayout from '../../layout/withAdminLayout';
import useFetchClientConfig from '../../api/FetchClientConfig';
import { updateClientConfig } from '../../redux/clientConfig/actionCreators';

const LeaderboardRoutes = lazy( () => import( './leaderboard' ) );
const onBoardingRoutes = lazy( () => import( './clientRoutes/clientRoute' ) );
const addNewClientRoute = lazy( () => import( './clientRoutes/onBoardingRoute' ) );
const instituteRoutes = lazy( () => import( './instituteRoutes/instituteRoutes' ) );
const studentRoutes = lazy( () => import( './studentRoutes/studentRoutes' ) )
const AddNewClientConfig = lazy( () => import( '../../container/clientTab/components/config' ) );
const LeadsTable = lazy( () => import( './LeadsRoutes/leadRoutes' ) )
const PromoCodesTable = lazy( () => import( './paymentRoutes/promoCodeRoutes' ) )
const TransactionsTable = lazy( () => import( './paymentRoutes/transactionRoutes' ) )
const AllFormTable = lazy( () => import( './formRoutes/formRoutes' ) )
const UTMRoutes = lazy( () => import( './UTMRoutes/utmRoutes' ) )
const BookingRoute = lazy( () => import( './bookingsRoutes/bookingsRoutes' ) )
const FlowRoute = lazy( () => import( './flowRoutes/flowRoutes' ) )
const MyResponses = lazy( () => import( './myResponsesRoutes/myResponsesRoutes' ) )
const MyProfileRoutes = lazy( () => import( './myProfile/myprofileRoutes' ) )
const CampaignsRoutes = lazy( () => import( './campaignRoutes/CampaignRoutes' ) )
const MentorRoutes = lazy( () => import( './mentorRoutes/mentorRoute' ) )
const CouponCodeRoutes = lazy( () => import( './couponCodeRoutes/couponCodeRoutes' ) )

function Admin() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const refreshToken = localStorage.getItem( 'refreshToken' );
  const { response: clientConfigDetails, isLoading: clientConfigLoading, FetchClientConfig } = useFetchClientConfig();

  useEffect( () => {
    if ( refreshToken ) {
      FetchClientConfig( refreshToken );
    }
  }, [refreshToken] )

  useEffect( () => {
    if ( clientConfigDetails && clientConfigDetails.data )
      localStorage.setItem( 'client_id', clientConfigDetails?.data?.client_id );
    dispatch( updateClientConfig(
      clientConfigDetails?.data
    ) );
  }, [clientConfigDetails] );

  return (
    <Switch>
      {
        clientConfigLoading &&
        <Spin />
      }
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={ path } component={ Dashboard } />
        <Route path={ `${ path }/institutes` } component={ instituteRoutes } />
        <Route path={ `${ path }/students` } component={ studentRoutes } />
        <Route path={ `${ path }/leaderboard` } component={ LeaderboardRoutes } />
        <Route path={ `${ path }/allclients` } component={ onBoardingRoutes } />
        <Route path={ `${ path }/addClient/new` } component={ addNewClientRoute } />
        <Route path={ `${ path }/addClient/config` } component={ AddNewClientConfig } />
        <Route path={ `${ path }/leads` } component={ LeadsTable } />
        <Route path={ `${ path }/allForms` } component={ AllFormTable } />
        <Route path={ `${ path }/promo_codes` } component={ PromoCodesTable } />
        <Route path={ `${ path }/transactions` } component={ TransactionsTable } />
        <Route path={ `${ path }/utm` } component={ UTMRoutes } />
        <Route path={ `${ path }/bookings` } component={ BookingRoute } />
        <Route path={ `${ path }/flow` } component={ FlowRoute } />
        <Route path={ `${ path }/myresponses` } component={ MyResponses } />
        <Route path={ `${ path }/myprofile` } component={ MyProfileRoutes } />
        <Route path={ `${ path }/campaigns` } component={ CampaignsRoutes } />
        <Route path={ `${ path }/coupon_code` } component={ CouponCodeRoutes } />
        <Route path={ `${ path }/mentor` } component={ MentorRoutes } />
      </Suspense>

    </Switch>
  );
}

export default withAdminLayout( Admin );
