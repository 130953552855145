export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const STUDENT_DATA = process.env.REACT_APP_STUDENT_DATA;
export const DOWNLOAD_STUDENT_DATA = process.env.REACT_APP_DOWNLOAD_STUDENT_DATA;
export const DOWNLOAD_SPECIFIC_STUDENT_DATA = process.env.REACT_APP_DOWNLOAD_SPECIFIC_STUDENT_DATA;
export const TEST_COMPLETION_STUDENT_DATA = process.env.REACT_APP_TEST_COMPLETION_STUDENT_DATA;
export const FETCH_SCHOOL = process.env.REACT_APP_FETCH_SCHOOL;
export const FETCH_SPECIFIC_SCHOOL_DATA = process.env.REACT_APP_FETCH_SPECIFIC_SCHOOL_DATA;
export const FETCH_ALL_CLIENTS = process.env.REACT_APP_FETCH_ALL_CLIENTS;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CREATE_SCHOOL = process.env.REACT_APP_CREATE_SCHOOL;
export const CREATE_STUDENT = process.env.REACT_APP_CREATE_STUDENTS;
export const CREATE_CLIENT = process.env.REACT_APP_CREATE_CLIENT;
export const CREATE_CLIENT_CONFIG = process.env.REACT_APP_CREATE_CLIENT_CONFIG;
export const UPDATE_CLIENT_CONFIG_BY_ID = process.env.REACT_APP_UPDATE_CLIENT_CONFIG_BY_ID;
export const FETCH_CLIENT_CONFIG_BY_ID = process.env.REACT_APP_FETCH_CLIENT_CONFIG_BY_ID;
export const IMAGE_UPLOAD = process.env.REACT_APP_IMAGE_UPLOAD;
export const FETCH_ANALYTICS = process.env.REACT_APP_FETCH_ANALYTICS;
export const DOWNLOAD_INSTITUTE_DATA = process.env.REACT_APP_DOWNLOAD_INSTITUTE_DATA;
export const DOWNLOAD_REPORT = process.env.REACT_APP_DOWNLOAD_REPORT;
export const FETCH_ALL_STUDENTS = process.env.REACT_APP_FETCH_STUDENTS;
export const FETCH_LEADERBOARD_DATA = process.env.REACT_APP_LEADERBOARD_DATA;
export const LEAD_RESPONSE_BY_FORM_ID = process.env.REACT_APP_FETCH_LEAD_BY_FORM_ID;
export const FETCH_FORM_DATA = process.env.REACT_APP_FETCH_FORM_DATA;
export const DOWNLOAD_LEADS = process.env.REACT_APP_DOWNLOAD_LEAD_BY_FORM_ID;
export const FETCH_USER_INFO = process.env.REACT_APP_GET_USER_INFO;
export const FETCH_CLIENT_CONFIG = process.env.REACT_APP_GET_CLIENT_CONFIG;
export const FETCH_QUESTIONS = process.env.REACT_APP_FETCH_QUESTIONS_BY_ID;
export const FETCH_FORM_CONFIG = process.env.REACT_APP_FETCH_FORM_CONFIG_BY_CODE;
export const CREATE_FORM_CONFIG = process.env.REACT_APP_CREATE_FORM_CONFIG;
export const UPDATE_FORM_CONFIG = process.env.REACT_APP_UPDATE_FORM_CONFIG_FORM_ID;
export const UPDATE_QUESTION_BY_ID = process.env.REACT_APP_UPDATE_FORM_BY_QUESTION_ID;
export const FETCH_QUESTION_BY_ID = process.env.REACT_APP_FETCH_QUESTION_BY_ID;

export const PAYMENTS_BASE_URL = process.env.REACT_APP_PAYMENTS_BASE_URL;
export const FETCH_ALL_PROMO_CODES = process.env.REACT_APP_FETCH_ALL_PROMO_CODES;
export const CREATE_PROMO_CODE = process.env.REACT_APP_CREATE_PROMO_CODE;
export const FETCH_PROMO_CODE_BY_ID = process.env.REACT_APP_FETCH_PROMO_CODE_BY_ID;
export const UPDATE_PROMO_CODE_BY_ID = process.env.REACT_APP_UPDATE_PROMO_CODE_BY_ID;
export const FETCH_ALL_TRANSACTIONS = process.env.REACT_APP_FETCH_ALL_TRANSACTIONS;

export const CREATE_UTM = process.env.REACT_APP_CREATE_UTM;
export const FETCH_UTM_MODULES = process.env.REACT_APP_FETCH_UTM_MODULES;
export const EXPORT_UTM_MODULES = process.env.REACT_APP_EXPORT_UTM_MODULES;
export const FETCH_SESSION_BOOKING = process.env.REACT_APP_FETCH_SESSION_BOOKINGS;
export const CREATE_SESSION_BOOKING = process.env.REACT_APP_CREATE_SESSION_BOOKINGS;
export const UPDATE_SESSION_BOOKING = process.env.REACT_APP_UPDATE_SESSION_BOOKING;
export const FETCH_SESSION_BOOKING_BY_ID = process.env.REACT_APP_FETCH_SESSION_BOOKINGS_BY_ID;

export const FETCH_ALL_FLOW_BLOCKS = process.env.REACT_APP_FETCH_ALL_FLOW_BLOCKS;

export const EMAIL_BASE_URL = process.env.REACT_APP_EMAIL_BASE_URL;
export const FETCH_EMAIL_PROVIDERS = process.env.REACT_APP_FETCH_EMAIL_PROVIDERS;
export const FETCH_PAYMENT_PROVIDERS = process.env.REACT_APP_FETCH_PAYMENT_PROVIDERS;

export const FETCH_INSTITUTE_TOPPERS_LEADERBOARD = process.env.REACT_APP_FETCH_INSTITUTE_TOPPERS_LEADERBOARD;
export const FETCH_ALL_FORM_DETAILS = process.env.REACT_APP_FETCH_ALL_FORM_DETAILS;

export const FETCH_STUDENT_CAMPAIGNS = process.env.REACT_APP_FETCH_STUDENT_CAMPAIGNS;
export const FETCH_INSTITUTE_CAMPAIGNS = process.env.REACT_APP_FETCH_INSTITUTE_CAMPAIGNS;
export const FETCH_INSTITUTE_STUDENTS = process.env.REACT_APP_FETCH_INSTITUTE_STUDENTS;
export const FETCH_STUDENT_METRIC = process.env.REACT_APP_FETCH_STUDENT_METRIC;

export const UPDATE_COMMENTS = process.env.REACT_APP_UPDATE_COMMENTS;
export const CAMPAIGN_USERS = process.env.REACT_APP_FETCH_CAMPAIGN_USERS;

export const FETCH_ME = process.env.REACT_APP_FETCH_ME;
export const UPDATE_ME = process.env.REACT_APP_UPDATE_ME;

export const UPDATE_INSTITUTE_PROFILE = process.env.REACT_APP_UPDATE_INSTITUTE_PROFILE;

export const FETCH_CLIENT_CONFIG_CLIENT_CODE = process.env.REACT_APP_FETCH_CLIENT_CONFIG_BY_CLIENT_CODE;

export const AUTH_PHONE_OTP = process.env.REACT_APP_AUTH_PHONE_OTP;
export const AUTH_PHONE_VERIFY_OTP = process.env.REACT_APP_AUTH_PHONE_VERIFY_OTP;

export const CREATE_COUPON_CODE = process.env.REACT_APP_CREATE_COUPON_CODE;
export const FETCH_ALL_COUPON_CODES = process.env.REACT_APP_FETCH_ALL_COUPON_CODES;
export const FETCH_ALL_COUPON_CODES_INSTITUTES = process.env.REACT_APP_FETCH_ALL_COUPON_CODES_INSTITUTE;

export const BULK_UPLOAD_INSTITUTE = process.env.REACT_APP_BULK_UPLOAD_INSTITUTES;
export const BULK_UPLOAD_STUDENT = process.env.REACT_APP_BULK_UPLOAD_STUDENTS;

export const FETCH_ALL_MENTORS = process.env.REACT_APP_FETCH_ALL_MENTORS;
export const CREATE_MENTORS = process.env.REACT_APP_CREATE_MENTORS;
export const MAP_STUDENTS_TO_MENTORS = process.env.REACT_APP_MAP_STUDENTS_TO_MENTORS;

export const RESET_PASSWORD_URL = process.env.REACT_APP_RESET_PASSWORD_URL;

export const RETAKE_TEST = process.env.REACT_APP_RETAKE_TEST;
export const UPDATE_RRESPONSE_CODES = process.env.REACT_APP_UPDATE_RESPONSE_CODES;
