import React from 'react';
import { Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import Cookies from 'js-cookie';
import { InfoWraper, UserDropDwon } from './auth-info-style';
// import Message from './message';
// import Notification from './notification';
// import Settings from './settings';
// import Support from './support';
import { Popover } from '../../popup/popup';
// import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';
// import Heading from '../../heading/heading';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const { isAuthenticate } = useSelector( state => {
    return {
      isAuthenticate: state.fb.auth.uid,
    };
  } );

  const history = useHistory();

  // const [state, setState] = useState( {
  //  flag: 'english',
  // } );
  // const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    const clientName = localStorage.getItem('clientName');
    const urlParams = JSON.parse(localStorage.getItem('urlParams'));
  
    const queryString = new URLSearchParams(urlParams).toString();
  
    if (isAuthenticate) {
      Cookies.remove('refresh_token', { domain: '.strideahead.io' });
      history.push(`/${clientName}?${queryString}`);
      dispatch(fbAuthLogout(dispatch(logOut())));
    } else {
      Cookies.remove('refresh_token', { domain: '.strideahead.io' });
      history.push(`/${clientName}?${queryString}`);
      dispatch(logOut());
    }
  };
  

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        {/*
        <figure className="user-dropdwon__info">
          <img src={ require( '../../../static/img/avatar/chat-auth.png' ) } alt="" />
          <figcaption>
            <Heading as="h5">Abdullah Bin Talha</Heading>
            <p>UI Expert</p>
          </figcaption>
        </figure>
        */}

        <Link to="/admin/myprofile" className="user-dropdwon__bottomAction" >
          <FeatherIcon icon="user" /> Profile
        </Link>
        <Link className="user-dropdwon__bottomAction" onClick={ SignOut } to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  // const onFlagChangeHandle = value => {
  //  setState( {
  //    ...state,
  //    flag: value,
  //  } );
  // };

  // const country = (
  //  <NavAuth>
  //    <Link onClick={ () => onFlagChangeHandle( 'english' ) } to="#">
  //      <img src={ require( '../../../static/img/flag/english.png' ) } alt="" />
  //      <span>English</span>
  //    </Link>
  //    <Link onClick={ () => onFlagChangeHandle( 'germany' ) } to="#">
  //      <img src={ require( '../../../static/img/flag/germany.png' ) } alt="" />
  //      <span>Germany</span>
  //    </Link>
  //    <Link onClick={ () => onFlagChangeHandle( 'spain' ) } to="#">
  //      <img src={ require( '../../../static/img/flag/spain.png' ) } alt="" />
  //      <span>Spain</span>
  //    </Link>
  //    <Link onClick={ () => onFlagChangeHandle( 'turky' ) } to="#">
  //      <img src={ require( '../../../static/img/flag/turky.png' ) } alt="" />
  //      <span>Turky</span>
  //    </Link>
  //  </NavAuth>
  // );

  return (
    <InfoWraper>
      {/*      
      <Message />
      <Notification />

      <Settings />
      <Support />
     
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={ country } trigger="click">
          <Link to="#" className="head-example">
            <img src={ require( `../../../static/img/flag/${ flag }.png` ) } alt="" />
          </Link>
        </Dropdown>
      </div>
      */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={ userContent } action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
